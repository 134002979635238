/* eslint-disable new-cap */
/* eslint-disable import/named */
/* eslint-disable react/button-has-type */

// material
import { CircularProgress} from '@mui/material';
import QRcode from 'react-qr-code'
// import { jsPDF } from "jspdf";
import {useParams} from  'react-router-dom'


// components
import { LoadingButton } from '@mui/lab';
// eslint-disable-next-line import/named
import { useEffect, useState } from 'react';
// import moment from 'moment';
import { cpfMask } from '../helpers/cpfValidator';
import { CnsMask } from '../helpers/cns';
import Page from '../components/Page';
// mock


import CardFront from '../assets/CardFront.png'
import CardBack from '../assets/cardBack.png'


import { 
  Container, 
  Content, 
  DivQrcode, 
  Content2, 
  DivSection,
  DivPhoto,
  DivForm,
  ButtonDiv,
  TextBlod
} from './style/styles'
import { api } from '../services/api';



// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const rend = useParams()
  const [user, setUser] = useState([])
  const [load, setLoad] = useState(true)

  const data = user[0]

 
  useEffect(() => {
    async function ApiUser () {
      try {
        setLoad(true)
      const response = await api.get(`/card/${rend.id}`)
        setUser(response.data);
        setLoad(false)
      } catch (error) {
        console.log(error)
      }
    }
    ApiUser()
  }, [rend.id])
   

  return (
    
    <Page title="Dashboard: Carterinha">
      { load ? (<CircularProgress />) :(
      <Container>
      <ButtonDiv> 
          <LoadingButton onClick={() => window.print()}>Imprimir</LoadingButton>
        </ButtonDiv>
        
        <Content>
           <img src={CardBack} alt="" width={850} />

           <DivQrcode> <QRcode size={140} value={`https://valida.cracha.saude.araguaina.to.gov.br/?code=${rend.id}`} /> </DivQrcode>
          </Content>

          <Content2>
          <img src={CardFront} alt="" width={850} />
            <DivSection>
               <DivPhoto>
                  <img 
                    src={data.files.url ? data.files.url : 'https://www.emmegi.co.uk/wp-content/uploads/2019/01/User-Icon.jpg'}  
                    width={180}
                    height={180} alt="Foto do usuário"
                    style={{borderRadius: 6}}
                  />
                <TextBlod>{data.blood_type === "Não sabe" ? ' ' : data.blood_type }</  TextBlod>

               </DivPhoto>


               <DivForm> 
                <p>Nome: {data.name}</p>
                  <p>DN: {data.date_nasc}</p>
                  <p>CPF: {cpfMask(data.cpf)} </p>
                  <p>CNS: {CnsMask(data.cns)}</p>

                  <p>EMISSÃO: {data.created_at} </p>
                  <p>VALIDADE: 2 Anos </p>

                  <p>N° CARTERINHA: {data.card_number} </p>
               </DivForm>
            </DivSection>
          </Content2>
          </Container>
    )}
    </Page>
  );
}


