import { useForm } from 'react-hook-form';
import {  useState, useEffect } from 'react';



// @mui
import {  Stack, TextField, CircularProgress} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { Form, FormikProvider } from 'formik';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';

import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../../../../services/api';

import { cpfMask } from '../../../../helpers/cpfValidator';
import { CnsMask } from '../../../../helpers/cns';
import { maskForDate } from '../../../../helpers/birthDateValidator';
import { maskFordDateFaker } from '../../../../helpers/maskFordDateFaker';
import { maskFake } from '../../../../helpers/maskFake';




// ----------------------------------------------------------------------

export default function EditForm() {
  const navigation = useNavigate()
  const rend = useParams()


  const {handleSubmit, formState} = useForm()
  const [name, setName] = useState('')
  const [cpf, setCpf] = useState('')
  const [birthDay, setBirthDate] = useState('')
  const [bloodType, setBloodType] = useState('')
  const [cns, setCns] = useState('')
  const [load, setLoad] = useState(false)

  const [file, setFile] = useState(null)

  
  
  
   const success = () => {
      toast.success('Editado')
      navigation('/dashboard/user')
    }

   
    useEffect(() => {
      async function ApiUser () {
        try {
          setLoad(true)
          const response = await api.get(`/card/${rend.id}`)
          const datas = response.data
          const data = datas[0]
          setName(data.name)
          setCpf(cpfMask(data.cpf))
          setBirthDate(maskForDate(data.date_nasc))
          setBloodType(data.blood_type)
          setCns(CnsMask(data.cns))

          setLoad(false)
        } catch (error) {
          console.log(error)
        }
      }
      ApiUser()
    }, [rend.id])


 const handleSubmitForm = async () => {
    try {
      const data = new FormData();
      data.append("name", name)
      data.append("dateNasc",maskFordDateFaker(birthDay))
      data.append("cpf", maskFake(cpf))
      data.append("cns", maskFake(cns))
      data.append("bloodType", bloodType)
      data.append("file", file)


     await api.put(`/card/${rend.id}`, data)
      success()
  
    } catch (error) {
      console.log(error)
      toast.error('Falha ao Editar')
    } 
  }

  function handleInput(e) {
    let { value } = e.target;
    value = cpfMask(e.target.value);

    setCpf(value);
  }

  function handleBirthDay(e) {
    let { value } = e.target;
    value = maskForDate(e.target.value);

    setBirthDate(value);
  }

  function handleCns(e) {
    let { value } = e.target;
    value = CnsMask(e.target.value);

    setCns(value);
  }



  return (
    <>
    <FormikProvider value={useForm()}>
    <Form autoComplete="off" noValidate onSubmit={handleSubmit(handleSubmitForm)}>
      {
        load ? (<CircularProgress style={{marginLeft: 220}} />) :
      <Stack spacing={3}>
        <TextField
          fullWidth
          autoComplete="username"
          type="text"
          label="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
          />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <TextField
              fullWidth
              autoComplete="username"
              type="text"
              label="Data de Nascimento"
              value={birthDay}
              onChange={(e) => handleBirthDay(e)}
              />

              <TextField
              fullWidth
              autoComplete="username"
              type="text"
              label="CPF"
              value={cpf}
              onChange={(e) => handleInput(e)}
              />

           </Stack>  

           <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
                fullWidth
                autoComplete="username"
                type="text"
                label="CNS"
                value={CnsMask(cns)}
                onChange={(e) => handleCns(e)}
              />
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={bloodType}
                label="Tipo Sanguíneo"
                onChange={(e) => setBloodType(e.target.value)}
              >
              <MenuItem value="Não sabe"> Não sabe</MenuItem>
              <MenuItem value="A +">A +</MenuItem>
              <MenuItem value="B +">B +</MenuItem>
              <MenuItem value="AB +">AB +</MenuItem>
              <MenuItem value="O +">O +</MenuItem>
              <MenuItem value="A -">A -</MenuItem>
              <MenuItem value="B -">B -</MenuItem>
              <MenuItem value="AB -">AB -</MenuItem>
              <MenuItem value="O -">O -</MenuItem>

             </Select>
          </Stack>  

          <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
           <h3>Selecione uma foto</h3>
          </Stack>
         <input 
           type="file"
           name="imagem"
           onChange={(e) => setFile(e.target.files[0])}
          />
      </Stack>

      }

      <Stack direction="row" alignItems="center" sx={{ my: 2 }}>
        {/* <h3>Selecione uma foto</h3> */}
      </Stack>
      <LoadingButton 
         style={{marginTop: '20px'}}
         fullWidth size="large" 
         type="submit" 
         variant="contained"
         disabled={!cpf || !name || !cns || !birthDay}
         loading={formState.isSubmitting}
      >
        Editar
      </LoadingButton>
    </Form>

   
  </FormikProvider>
  </>
  );
}


