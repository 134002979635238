import {  useRef, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText,Modal, Box, Stack, Button, CircularProgress  } from '@mui/material';

// material
// component
import Swal from 'sweetalert2'
import moment from 'moment';

import { api } from '../../../services/api';

import Iconify from '../../../components/Iconify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '2px',
  pt: 2,
  px: 4,
  pb: 3,
};


// ----------------------------------------------------------------------

export default function UserMoreMenu(
    id, 
  ) {
  const ref = useRef(null);
  const navigate = useNavigate()
  
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false)
  const [load, setLoad] = useState(false)
  const date = new Date()
  const formatDate = moment(date).format('YYYY-MM-DD')

  const stringId = id.id.toString()

  const handleClose = () => {
    setOpen(false)
  }

  const handleCardValidation = async () => {
    setLoad(true)
    try {
      await api.patch(`card/${stringId}`, {
        dateToday: formatDate
      })
      Swal.fire(
        'Sucesso!',
        `Carteirinha válida por mais dois anos`,
      )
    } catch (error) {
      console.log('error')
      Swal.fire({
        title: 'Opa',
        text: error.message
      })
    }finally {
      setLoad(false)
      setOpen(false)
    }
  }

  
  function handlePush () {
    navigate(`/dashboard/products/${id.id}`)
  }

  function handlePushSecond () {
    navigate(`/dashboard/productsSecond/${id.id}`)
  }

  function handleEdit () {
    navigate(`/dashboard/Edit/${id.id}`)
  }

   async function handleDelete() {
    try {
      Swal.fire({
        title: 'Opa',
        text: "Tem certeza disso ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Excluir'
      }).then((result) => {
        if (result.isConfirmed) {
          api.delete(`card/${id.id}}`)
          Swal.fire(
            'Excluído!',
            `Usúario ${id.name.match(/^[^\s]+/)} excluído`,
            ''
          )
          setTimeout(() => {
            window.location.reload()
          }, "2000")
        }
      })
    } catch (error) {
      console.error(error)
      toast.error('Erro ao excluir usúario, tente novamnete !')
    } 
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        >
        <Box sx={{ ...style, width: 400 }}>
            <h3 id="parent-modal-title">Renovar válidade</h3>
            <p id="parent-modal-description">
              Tem certeza que deseja renovar a válidade dessa carteirinha por mais dois anos?
            </p>
            <Stack flexDirection='row' alignItems='center' justifyContent='center' gap='8px' mt='10px'>
              <Button onClick={handleClose} variant='outlined' color='error'>Cancelar</Button>
              <Button sx={{minWidth: '100px'}} disabled={load} onClick={handleCardValidation} variant='contained' color='info'> {load ? <CircularProgress size="25px" /> : 'Confirmar'}</Button>
            </Stack>
        </Box>
    </Modal>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
        <MenuItem id  onClick={() => handlePush()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Imprimir cartão " primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem id  onClick={() => setOpen(true)} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="radix-icons:update" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Renovar válidade" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
         <MenuItem id  onClick={() => handlePushSecond()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="ic:baseline-note-alt" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Imprimir 2° via cartão" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem id  onClick={() => handleEdit()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="fa-solid:user-edit" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem id  onClick={() => handleDelete()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="bi:trash" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Excluir" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}


