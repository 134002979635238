import { useRef, useState, useContext} from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {  Divider, Stack, MenuItem, Avatar, IconButton} from '@mui/material';


import { AuthContext } from '../../context/authContext';
// components
import MenuPopover from '../../components/MenuPopover';
// mocks_
// import account from '../../_mock/account';

import avatar from '../../assets/suporte.png';


// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  // {
  //   label: 'Sair',
  //   icon: 'eva:home-fill',
  //   linkTo: '/login',
  // },
  // {
  //   label: 'Profile',
  //   icon: 'eva:person-fill',
  //   linkTo: '#',
  // },
  // {
  //   label: 'Settings',
  //   icon: 'eva:settings-2-fill',
  //   linkTo: '#',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const {logout} = useContext(AuthContext)

  const handleLogout = () => {
    logout()
  }

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar
          alt="Avatar"
          src={avatar}
          sx={{ width: 56, height: 56 }}
        />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.85,
          },
        }}
      >
        {/* <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            sair
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            Sair
          </Typography>
        </Box> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        <MenuItem onClick={handleLogout} sx={{ }}>
          Sair
        </MenuItem>
      </MenuPopover>
    </>
  );
}
