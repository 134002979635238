import {  useRef, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import Swal from 'sweetalert2'

import { api } from '../../../services/api';

import Iconify from '../../../components/Iconify';



// ----------------------------------------------------------------------

export default function UserMoreMenu(
    id, 
  ) {
  const ref = useRef(null);
  const navigate = useNavigate()
  
  const [isOpen, setIsOpen] = useState(false);

  
  function handlePush () {
    navigate(`/dashboard/products/${id.id}`)
  }

  function handlePushSecond () {
    navigate(`/dashboard/productsSecond/${id.id}`)
  }

  function handleEdit () {
    navigate(`/dashboard/Edit/${id.id}`)
  }

   async function handleDelete() {
    try {
      Swal.fire({
        title: 'Opa',
        text: "Tem certeza disso ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Excluir'
      }).then((result) => {
        if (result.isConfirmed) {
          api.delete(`card/${id.id}}`)
          Swal.fire(
            'Excluído!',
            `Usúario ${id.name.match(/^[^\s]+/)} excluído`,
            ''
          )
          setTimeout(() => {
            window.location.reload()
          }, "2000")
        }
      })
    } catch (error) {
      console.error(error)
      toast.error('Erro ao excluir usúario, tente novamnete !')
    } 
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
        <MenuItem id  onClick={() => handlePush()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Imprimir cartão " primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
         <MenuItem id  onClick={() => handlePushSecond()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="ic:baseline-note-alt" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Imprimir 2° via cartão" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem id  onClick={() => handleEdit()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="fa-solid:user-edit" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem id  onClick={() => handleDelete()} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Iconify icon="bi:trash" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Excluir" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}


