import { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
// import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import Products from './pages/Products';
import ProdutsSecond from './pages/ProductsSecond';
// import DashboardApp from './pages/DashboardApp';
import Register from './pages/Register';
import Edit from './pages/Edit';
// import Blog from './pages/Blog';
import { AuthContext } from './context/authContext';

// ----------------------------------------------------------------------

export default function Router() {

  // eslint-disable-next-line react/prop-types
  const Private = ({children}) => {
    const {authenticated, loading} = useContext(AuthContext)

    if(loading) {
      return <CircularProgress />
    }

    if(!authenticated) {
      return <Navigate to='/login' />
    }

    return children
  }

  return useRoutes([
    {
      path: '/dashboard',
      element:  <DashboardLayout />,
      children: [
        { path: 'user', element: <Private> <User /> </Private>},
        { path: 'register', element:<Private>  <Register /> </Private>},
        { path: 'Edit/:id/', element:<Private>  <Edit /> </Private>},
        // { path: 'app', element: <Private> <DashboardApp /> </Private>},
        { path: `products/:id`, element: <Private> <Products/> </Private>},
        { path: `productsSecond/:id`, element: <Private> <ProdutsSecond/> </Private>},
        // { path: 'blog', element: <Private> <Blog /> </Private>},
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Private> <Navigate to="/dashboard/user"/>  </Private> },
        { path: 'login', element: <Login /> }
      ],
    },
  ]);
}

