import { useState, useContext } from 'react';

// @mui
import {  Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { Form, FormikProvider } from 'formik';
import { AuthContext } from '../../../context/authContext';
import Iconify from '../../../components/Iconify';
import { cpfMask } from '../../../helpers/cpfValidator';


// ----------------------------------------------------------------------

export default function LoginForm() {
  // const navigate = useNavigate();
  const {login} = useContext(AuthContext)
  const [showPassword, setShowPassword] = useState(false);
 const [cpf, setCpf] = useState('')
  const [password, setPassword] = useState('')

  
  const [submiting, setSubimiting] = useState(false);

  const handleSubmit= (event) => {
    try {
      setSubimiting(true)
      event.preventDefault();
      login(cpf, password)
      setSubimiting(false)
    } catch (error) {
      setSubimiting(false)
    }
  }

  function handleInput(e) {
    let { value } = e.target;
    value = cpfMask(e.target.value);

    setCpf(value);
  }



  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={cpf}>
    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField
          fullWidth
          autoComplete="username"
          type="email"
          label="CPF"
          value={cpf}
          onChange={(e) => handleInput(e)}
          />

        <TextField
          fullWidth
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          label="Password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleShowPassword} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel
          control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
          label="Remember me"
        />

        <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
          Forgot password?
        </Link>
        
      </Stack> */}

      <LoadingButton 
         style={{marginTop: '20px'}}
         fullWidth size="large" 
         type="submit" 
         variant="contained"
         disabled={!cpf || !password}
         loading={submiting}
      >
        Entrar
      </LoadingButton>
    </Form>
  </FormikProvider>
  );
}
