import styled from "styled-components";


export const Container = styled.div`
    margin-left: 20rem;

    @media print {
        margin-left: 8rem;
        margin-top: -10rem;

    }

    @page {
        size: A4 landscape;
        
    }

`;

export const ButtonDiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 4.8rem;

    button {
        width: 10rem;
        height: 3rem;
        border-radius: 10px;
        color: #fff;
        background-color: #74CAFF;
        border: 0;
        cursor: pointer;
        transition: background-color 2s;
        font-weight: bold;
        font-size: 24px;

        &:hover {
            background-color: #74CAdF;

        }

        @media print {
        display: none;
    }
    }
`


export const Content = styled.div`
    z-index: 2;
    display: flex;
    flex-direction: column;
    border-radius: 70px;
    width: 850px;
    
`




export const  DivQrcode = styled.div`
    height: 9.8rem;
    margin-left: 16.8rem;
    margin-top: -12.6rem;

    z-index: 3;

`;




export const Content2 = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 70px;
    width: 850px;
    z-index: 2;
    margin-top: 5rem;
    position: relative;

    @media print {
        margin-top: 30rem;
    }
`


export const DivSection = styled.div`
    display: flex;
    flex-direction: row;
    padding: 3rem 0.1rem;
`;

export const TextBlod = styled.h2`
    color: red;
    font-size: 40px;
    margin-left: 2rem;
    z-index: 3;
`

export const DivPhoto = styled.div`
    display: flex;
    margin-top: -28rem;
    margin-left: 3.2rem;
    flex-direction: column;
    z-index: 3;
    align-items: center;


    h3 {
        position: absolute;
        top: 2.5rem;
        right: 15rem;
        font-size: 24px;
        font-family: 'Roboto', sans-serif;
    }
`;

export const DivForm = styled.div`
    width: 100%;
    text-transform: uppercase;
    margin-top: -28rem;
    z-index: 3;

    font-size: 16px;
    font-weight: 800;
    position: absolute;

    p:nth-of-type(1){
        font-size: 18px;
        margin-left: 15.8rem;
        margin-top: 0.4rem;
        max-width: 33rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    p:nth-of-type(2){
        margin-left: 15.8rem;
        margin-top: 1.3rem;
    }
    p:nth-of-type(3){
        margin-left: 24.3rem;
        margin-top: -1.6rem;
    }
    p:nth-of-type(4){
        margin-left: 35.8rem;
        margin-top: -1.5rem;
    }
    p:nth-of-type(5){
        margin-left: 15.8rem;
        margin-top: 1.4rem;
    }
    p:nth-of-type(6){
        margin-left: 28rem;
        margin-top: -1.5rem;
    }
    p:nth-of-type(7){
        margin-left: 15.8rem;
        margin-top: 1.3rem;
    }
    
`;







