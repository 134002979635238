/* eslint-disable react/prop-types */
import { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { api, createSession } from "../services/api";


export const AuthContext = createContext([]);

export const AuthProvider = ({children}) => {
    const navigate = useNavigate()
    const [user, setUser] = useState(null)
    const [loading, setLoadig] = useState(true)

    useEffect(() => {
        const recoverdUser = localStorage.getItem('user')

        const token = localStorage.getItem('token')

        if(recoverdUser) {
            setUser(JSON.parse(recoverdUser))
        }

        if(token) {
            api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`
        }

        if(!token) {
            localStorage.removeItem('user')
        }

        setLoadig(false)
    }, [])

    const login = async (cpf,password) => {
        try {
            const response = await createSession(cpf, password)

            const loggedUser = response.data;
            const {token} = response.data.token;

            localStorage.setItem('user', JSON.stringify(loggedUser))
            localStorage.setItem('token', JSON.stringify(token))

            api.defaults.headers.Authorization = `Bearer ${token}`

            setUser(loggedUser)
            navigate('/dashboard/user')

            toast.success("Tudo certo !", {

            })
        } catch (error) {
            console.log(error)
            toast.error("Falha ao efetuar login, tente novamente !", {

            })
        }
    }


    const logout = () => {

        localStorage.removeItem('user')
        localStorage.removeItem('token')

        api.defaults.headers.Authorization = null
        setUser(null)
        navigate('/login')
    }

    return (
        <AuthContext.Provider
          value={{authenticated: !!user, user, loading, login, logout}}
        >
            {children}
        </AuthContext.Provider>
    )
  
}

